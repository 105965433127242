import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

const Logo = ({ fill = "#fff" }: { fill?: string }) => {
  return (
    <LogoContainer>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40px"
      viewBox="0 0 200 30"
      fill="none"
      className="Logo__Desktop"
    >
      <g clipPath="url(#clip0)" transform="translate(-4.86 -1.202) scale(1.0149) translate(-14.601 -6.656) scale(.37404)" fill={fill}>
        <path d="M66.02 37.419v20.915h25.198v25.198h20.914V37.419H91.218z"></path>
        <path d="M70.178 63.55H85.297V78.669H70.178z"></path>
      </g>
      <text
        x="35.541"
        y="24.395"
        style={{ lineHeight: "1.25" }}
        strokeWidth="0.486"
        fontFamily="sans-serif"
        fontSize="19.422"
        transform="translate(-4.86 -1.202) scale(1.0149)"
        fill={fill}
      >
        <tspan x="35.541" y="24.395" fontFamily="Poppins">
          <tspan fontWeight="bold">TEGUH</tspan>
          <tspan fontFamily="Raleway"> SANTOSO</tspan>
        </tspan>
      </text>
    </svg>

      <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40px"
      viewBox="0 0 200 30"
      fill="none"
      className="Logo__Mobile"
    >
      <g transform="translate(-4.86 -1.202) scale(1.0149) translate(-14.601 -6.656) scale(.37404)" fill={fill}>
        <path d="M66.02 37.419v20.915h25.198v25.198h20.914V37.419H91.218z"></path>
        <path d="M70.178 63.55H85.297V78.669H70.178z"></path>
      </g>
      <text
        x="35.541"
        y="24.395"
        style={{ lineHeight: "1.25" }}
        strokeWidth="0.486"
        fontFamily="sans-serif"
        fontSize="19.422"
        transform="translate(-4.86 -1.202) scale(1.0149)"
        fill={fill}
      >
        <tspan x="35.541" y="24.395" fontFamily="Poppins" fill={fill}>
          <tspan fontWeight="bold">TEGUH </tspan>
          <tspan fontFamily="Raleway">STS</tspan>
        </tspan>
      </text>
    </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }
  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    .Logo__Mobile{
      display: block;
    }
  `}
`;
